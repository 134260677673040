<template>
  <main>
    <section>
      <v-row no-gutters>
        <v-col
          class="main-bg show-mobile"
          cols="12"
          lg="8"
          md="7"
          sm="12"
          xl="8"
          xs="6"
        >
          <v-img :src="loginBg" cover height="100vh" width="100%"></v-img>
          <div class="overlay-cover"></div>
        </v-col>
        <v-col cols="12" lg="4" md="5" sm="12" xl="4" xs="6">
          <form-login
            :loading="loading"
            :login-data="loginData"
            @loginAccess="signUp"
          ></form-login>
        </v-col>
      </v-row>
    </section>
    <ActionPopUp
      :dialog="dialog"
      :type="type"
      iteName=""
      @closeAction="dialog = false"
    ></ActionPopUp>
  </main>
</template>

<script>
import FormLogin from "@/components/FormLogin.vue";
import { mapActions } from "vuex";
import loginBg from "@/assets/images/gobiernoOaxaca.png";
import ActionPopUp from "@/components/ActionPopUp.vue";

export default {
  name: "Login",
  components: { ActionPopUp, FormLogin },
  data() {
    return {
      loginData: {
        password: "",
        email: "",
      },
      loading: false,
      dialog: false,
      type: "error",
    };
  },
  computed: {
    loginBg: () => loginBg,
  },
  methods: {
    ...mapActions("auth", ["fetchUser"]),
    async signUp() {
      localStorage.clear();
      this.loading = true;
      const statusLogin = await this.fetchUser(this.loginData);
      this.loading = false;
      if (statusLogin.status !== "failed") {
        this.$router.push("/dashboard");
        return;
      } else {
        this.dialog = true;
      }
    },
  },
};
</script>

<style lang="scss">
.main-bg {
  position: relative;
  height: 100vh;
}

.main-bg .overlay-cover {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: block;
  background: rgba(0, 0, 0, 0.1);
}
</style>
