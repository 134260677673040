<template>
  <v-row class="main-row-container" no-gutters>
    <v-col cols="12" class="d-flex align-end flex-column pa-2">
      <v-btn :dark="$vuetify.theme.dark" icon @click="toggleTheme">
        <v-icon>mdi-brightness-4</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <main>
        <section class="content-form main-display-column-center">
          <div>
            <div class="display-items-logo">
              <img class="logo-lg-img" :src="$vuetify.theme.dark ? backofficeLogo : backofficeLogo" alt="BACKOFFICE">
              <span class="login-version">v{{ applicationVersion }}</span>
            </div>
            <div>
              <div class="mt-3">
                <h1 class="login-title">Inicio de sesión</h1>
                <div class="mt-2">
                  <span class="login-subtitle">Ingresa tus credenciales</span>
                </div>
              </div>
            </div>
            <div>
              <div class="mt-6">
                <v-form ref="loginForm" v-model="validForm" autocomplete="off">
                  <v-row no-gutters>
                    <v-col
                        class="pa-0"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                    >
                      <label class="input-label" for="E-mail">E-mail</label>
                      <v-text-field
                          v-model="loginData.email"
                          id="E-mail"
                          hide-details
                          outlined
                          :rules="emailRules"
                          color="backofficeInput"
                      />
                    </v-col>
                    <v-col
                        class="pa-0 mt-6"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                    >
                      <label class="input-label" for="Password">Contraseña</label>
                      <v-text-field
                          v-model="loginData.password"
                          hide-details
                          outlined
                          :type="!marker ? 'text' : 'password'"
                          :append-icon="!marker ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                          @click:append="toggleMarker"
                          :rules="required"
                          color="backofficeInput"
                          @keyup.enter="signIn"
                      >

                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
              <div class="mt-8 mb-16">
                <v-btn rounded :disabled="!validForm" :class="validForm ? 'btn-active v-btn-primary' : 'v-btn-primary'"
                       @click="signIn"
                       :loading="loading">
                 Iniciar sesión
                </v-btn>
              </div>
            </div>
          </div>
        </section>
      </main>
    </v-col>
  </v-row>
</template>

<script>
import backofficeLogo from '@/assets/images/logoOaxacabg.png'
import {emailRules, required} from "@/utils/validationRule"
import {version} from "../../package.json"


export default {
  name: "FormLogin",
  props: {
    loginData: {
      type: Object, default: () => {
      }
    },
    loading: {
      type: Boolean, default: false
    }
  },
  data() {
    return {
      marker: true,
      validForm: false,
      emailRules,
      required,
      applicationVersion: version
    }
  },
  computed: {
    backofficeLogo: () => backofficeLogo
  },
  methods: {
    toggleMarker() {
      this.marker = !this.marker
    },
    signIn() {
      this.$emit('loginAccess')
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  }
}
</script>

<style lang="scss">
.main-row-container {
  .content-form {
    height: 100vh;
    padding: 25px 50px 25px 50px;
    border-radius: 8px;
    z-index: 1;
    @media (max-width: 950px) {
      padding: 25px 30px 25px 30px;
    }

    .login-title {
      font-family: "Montserrat-Medium";
      font-size: 35px;
      font-weight: 500;
    }

    .login-subtitle {
      font-family: "Montserrat-Regular";
      font-size: 15px;
    }
  }
}

.border-input {
  .v-input__slot {
    border: 1px solid var(--v-backofficeInput-base) !important;

    label {
      color: var(--v-backofficeInput-base) !important;
    }
  }
}

.display-items-logo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;
}

.login-version {
  font-size: small;
  font-family: "Montserrat-MediumItalic";
}

</style>
